.controls-container {
	display: flex;
	flex-direction: column;
	border: 1px dashed #e2e2e2;
	padding: 10px 15px;
	width: 100%;

	.margin-b-1 {
		margin-bottom: 5px;
	}
	.general-controls {
		//display: flex;
		align-self: flex-start;
		flex-direction: row;
		padding-bottom: 10px;
		width: 100%;

		.controls-container-info {
			font-size: 30px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			float: right;
		}
	}

	.control-container-tags {
		border-top: 1px solid #b2b3b5;
		align-self: auto;
		max-width: 1000px;
		display: inline-block;
		padding-top: 10px;
	}

	.dropdown-menu {
		max-height: 270px;
		width: max-content;
		overflow-y: scroll;
	}

	.more-occurrence-dropdown {
		border-radius: 0;
		font-size: small;
		font-weight: 600;
		height: 38px !important;
		border-top: 1px solid black;
		border-bottom: 1px solid black;
		border-right: 1px solid #000;
		border-bottom-right-radius: 0.25rem;
		border-top-right-radius: 0.25rem;
	}
	.additional-tags-dropdown-item {
		padding: 10px 5px;
		.option-logo-player,
		.option-logo-team,
		.option-logo-tournament,
		.option-logo-venue,
		.option-logo-coach {
			width: 35px;
			height: 35px;
		}
	}
}
