@media (max-width: 800px) {
  .my-masonry-grid {
    margin-left: -15px; /* gutter size offset */
  }
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  //margin-left: 40px; /* gutter size offset */
  margin-top: 20px; /* gutter size offset */
  width: auto;
  height: auto;
}
.my-masonry-grid_column {
  margin-left: -30px;
  padding-left: 50px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 50px;
}

.image-container {
  .image {
    margin-bottom: 1px;
  }

  .image-footer {
    width: 100%;
    position: absolute;
    bottom: 0;

    .image-description {
      background-color: rgba(0, 0, 0, 0.8);
      color: #ffffff;
    }

    .actions-wrapper {
      left: 0;

      .btn {
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        &:first-child {
          border-bottom-left-radius: 4px;
        }
        &:last-child {
          border-bottom-right-radius: 4px;
        }
      }
    }
  }
}

.lazy-load-container {
  height: 200px;
  overflow: hidden;
}

.image-cells-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.selected-image {
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
}

.selected-border {
  border: 5px solid #ffc107;
}

.selected-size {
  background-color: #ffc107;
}

.images-container {
  height: 500px;
}

.add-image-item {
  position: relative;
  height: 100%;
}

.download-size-button {
  border: white 1px solid;

  &:hover {
      background: #ffc107;
  }
}

.download-sizes-menu {
  position: absolute;
  width: 25%;
  z-index: 1;
  margin-left: 2%;
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  background-color: transparent !important;
  padding-top: 2%;
}

.selected-download-size-background {
    &:before {
      content: '';
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      cursor: pointer;
    }

    &.selected-item:after {
      content: '';

  }
}

.download-size-label {
  padding: inherit;
}
