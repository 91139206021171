@import 'variables';

$toggle-item-size: 24px;
$toggle-item-gap: 4px;
$content-container-height: unset;
$editor-min-height: 300px;

/* Toolbar */
.collaborative-editor-toolbar {
	background-color: $toolbar-background-color;
	border: 1px solid $editor-border-color;
	border-radius: 8px 8px 0 0;
	padding: 12px 12px calc(12px - #{$toggle-item-gap}) 12px;

	.toolbar-toggle-item {
		flex: 0 0 auto;
		color: $toolbar-item-color !important;
		width: $toggle-item-size;
		height: $toggle-item-size;
		border: none;
		border-radius: 4px;
		padding: 0 5px;
		margin-right: $toggle-item-gap;
		margin-bottom: $toggle-item-gap;

		display: inline-flex;
		font-size: 13px;
		line-height: 1;
		align-items: center;
		justify-content: center;
		background-color: transparent !important;

		&.active {
			background-color: $toolbar-item-background-color-active !important;
		}

		&:hover:not(:disabled):not(.active) {
			background-color: rgba($color: $toolbar-item-background-color-active, $alpha: 0.5) !important;
		}

		&:focus {
			position: relative;
			box-shadow: 0 0 0 2px $toolbar-item-background-color-active;
		}
	}

	.toolbar-separator {
		margin-top: calc(#{$toggle-item-gap} / 2);
		margin-right: $toggle-item-gap;
		width: 2px;
		height: calc(#{$toggle-item-size} - #{$toggle-item-gap});
		background-color: $toolbar-item-background-color-active;
	}
}

/* Toolbar pop-ups */
.collaborative-editor-toolbar-popover > .popover {
	z-index: 1111;

	.link-button + .link-button {
		margin-left: 8px;
	}

	.Fieldset {
		display: flex;
		gap: 20px;
		align-items: center;
		margin-bottom: 8px;
	}

	.Label {
		font-size: 13px;
		width: 75px;
	}

	.Input {
		width: 100%;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		flex: 1;
		border-radius: 4px;
		padding: 0 10px;
		font-size: 13px;
		line-height: 1;
		height: 25px;
	}

	.Text {
		margin: 0;
		font-size: 15px;
		line-height: 19px;
		font-weight: 500;
	}
}

/* Editor */
.ProseMirror {
	border: 1px solid $editor-border-color;
	border-top: none;
	padding: 16px;
	outline: none;

	&[contenteditable='true'] {
		min-height: $editor-min-height;
	}

	* {
		box-sizing: border-box;
	}

	p,
	h3 {
		word-break: break-word;
	}

	.editor-widget-preview {
		cursor: pointer;

		&.content-block {
			& > :first-child {
				border: 1px solid $editor-border-color;
				border-radius: 4px;
				overflow: auto;
			}

			.content-container {
				height: $content-container-height;
			}
		}

		&.widget-v1,
		&.widget-v2 {
			border: 1px solid $editor-border-color;
			border-radius: 4px;
			overflow: hidden;
		}

		.block-content {
			.content-image {
				max-width: 250px;
				max-height: 250px;
			}

			.gallery-title,
			.image-title {
				padding-left: 20px;
				overflow: hidden;
			}
		}
	}

	table {
		border-collapse: collapse;
		margin: 0;
		overflow: hidden;
		table-layout: fixed;
		width: 100%;

		td,
		th {
			border: 2px solid #ced4da;
			box-sizing: border-box;
			min-width: 1em;
			padding: 3px 5px;
			position: relative;
			vertical-align: top;

			> * {
				margin-bottom: 0;
			}
		}

		th {
			background-color: #f1f3f5;
			font-weight: bold;
			text-align: left;
		}

		.selectedCell:after {
			background: rgba(200, 200, 255, 0.4);
			content: '';
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			pointer-events: none;
			position: absolute;
			z-index: 2;
		}

		.column-resize-handle {
			background-color: #adf;
			bottom: -2px;
			position: absolute;
			right: -2px;
			pointer-events: none;
			top: 0;
			width: 4px;
		}

		p {
			margin: 0;
		}
	}

	blockquote {
		border-left: 3px solid rgba(#0d0d0d, 0.1);
		padding-left: 1rem;
	}

	&[contenteditable='false'] .editor-widget-preview {
		cursor: default;
	}
}
